<template>
	<header class="c-navigation-header">
		<BurgerMenu
			v-model="burgerMenuState"
			class="absolute left-0 top-0 w-full"
			:items="mainItems"
			:cap-buttons="[contactPage].filter(Boolean)"
		/>
		<nav class="w-full flex justify-start items-center">
			<NuxtLinkExt
				v-if="logo"
				class="mr-auto flex-shrink-0 max-w-logoWidth"
				:to="frontpageUrl"
			>
				<UmbracoImageExt
					:alt="title || logo.altText"
					:widths="[150, 211]"
					:source-url="logo.cropUrl || logo.url"
					:source-width="logo.width"
					:source-height="logo.height"
					:aspect-ratio="logo.width / logo.height"
					:style="logoStyle"
					class="bg-transparent max-w-logoWidth"
					image-class-names="object-contain"
				/>
			</NuxtLinkExt>
			<NuxtLinkExt
				v-else-if="title"
				class="mr-auto flex-shrink-0 max-w-logoWidth"
				:to="frontpageUrl"
			>
				<span class="text-h2 font-semibold" v-text="title"></span>
			</NuxtLinkExt>

			<ul
				v-if="!compactLayout && mainItems && mainItems.length"
				class="mx-auto flex justify-start items-center"
				:class="['<1280:hidden']"
			>
				<li
					v-for="item in mainItems"
					:key="item.id"
					class="c-navigation-header__main-item"
				>
					<NuxtLinkExt
						:to="item.url"
						class="
							text-text
							hover:text-primary-button
							text-body
							font-semibold
							uppercase
							px-md
							py-4
						"
						v-text="item.title"
					/>
				</li>
			</ul>

			<ul
				class="
					c-navigation-header__persistent-buttons
					ml-auto
					relative
					flex-shrink-0 flex
					justify-start
					items-center
					gap-x-10
					z-10
				"
			>
				<li
					v-if="contactPage && !compactLayout"
					:class="['<1280:hidden']"
				>
					<BaseButton
						tag="NuxtLinkExt"
						type="primary-light"
						:to="contactPage.url"
						:target="contactPage.target"
					>
						{{ contactPage.name }}
					</BaseButton>
				</li>
				<li
					:class="{
						'>=1280:hidden': !compactLayout,
					}"
				>
					<BaseButton
						:type="burgerMenuState ? 'white' : 'primary-light'"
						:aria-label="burgerMenuState ? 'Luk Menu' : 'Åbn Menu'"
						@click="() => (burgerMenuState = !burgerMenuState)"
					>
						<span class="flex items-center my-auto gap-10">
							<Transition
								name="t-navigation-header__burger"
								mode="out-in"
							>
								<SvgBurger
									v-if="!burgerMenuState"
									key="burger"
									class="w-16 h-16"
								/>
								<SvgCross
									v-if="burgerMenuState"
									key="cross"
									class="w-16 h-16"
								/>
							</Transition>
							Menu
						</span>
					</BaseButton>
				</li>
				<li v-if="searchPage">
					<BaseButton
						tag="NuxtLinkExt"
						:type="burgerMenuState ? 'primary-light' : 'primary'"
						:to="searchPage.url"
						:target="searchPage.target"
					>
						<span class="flex items-center my-auto gap-10">
							<SvgSearch class="w-16 h-16" />
							Søg
						</span>
					</BaseButton>
				</li>
			</ul>
		</nav>
	</header>
</template>

<script>
import UmbracoImageExt from '~/components/shared/UmbracoImageExt.vue';
import BurgerMenu from '~/components/subsite/BurgerMenu.vue';
// import SvgCaret from '~/assets/svgs/caret-icon.svg?inline';
import SvgBurger from '~/assets/svgs/burger-icon.svg?inline';
import SvgCross from '~/assets/svgs/cross-icon.svg?inline';
import SvgSearch from '~/assets/svgs/search-icon.svg?inline';

export default {
	name: 'NavigationHeader',

	components: {
		UmbracoImageExt,
		BurgerMenu,
		SvgBurger,
		SvgCross,
		SvgSearch,
	},

	data() {
		return {
			logo: this.$store.state.site?.logo,
			title: this.$store.state.site?.name || '',
			frontpageUrl: this.$store.state.site?.url || '/',
			contactPage: this.$store.state.site?.navigation?.contactPage,
			searchPage: this.$store.state.site?.searchPage,

			compactLayout:
				this.$store.state.site?.navigation?.showAsMobile ?? true,
			mainItems: this.$store.state.site?.navigation?.main,
			secondaryItems: this.$store.state.site?.navigation?.secondary,

			burgerMenuState: false,
		};
	},
	computed: {
		logoStyle() {
			let width = Math.floor(Math.min(211, this.logo?.width || 211));
			const height =
				(width / (this.logo?.width || 211)) *
				(this.logo?.height || 120);

			// Make sure it fits the height
			if (height > 120) {
				width = Math.floor((width / height) * 120);
			}

			// Make sure it fits on mobile as well
			if (
				(((width / (this.logo?.width || 211) / 211) *
					150 *
					(this.logo?.height || 120)) /
					120) *
					110 >
				110
			) {
				width = Math.floor((width / height) * 110);
			}

			return {
				width: `${width}px`,
			};
		},
	},

	mounted() {
		window.addEventListener('click', this.checkClickOutside, true);
	},
	beforeDestroy() {
		window.removeEventListener('click', this.checkClickOutside, true);
	},

	methods: {
		checkClickOutside(event) {
			if (event.target.closest('.c-navigation-header')) {
				return;
			}
			this.burgerMenuState = false;
		},
	},
};
</script>

<style lang="postcss">
.c-navigation-header {
	@apply relative isolate w-full h-menu min-h-fit flex justify-start items-center gap-x-10 z-50 px-layout-margin print:hidden;
}
.c-navigation-header__main-item {
	@apply flex justify-start items-center;
	position: relative;
	min-height: 18px;

	&:not(:last-child) {
		@apply border-r border-black border-opacity-10;
	}
}

.t-navigation-header__burger-enter-active,
.t-navigation-header__burger-leave-active {
	@apply transition duration-200 ease-smooth-out;
}
.t-navigation-header__burger-enter {
	@apply opacity-0 transform -rotate-45;
}
.t-navigation-header__burger-leave-to {
	@apply opacity-0 transform rotate-45;
}
</style>
