<template>
	<Transition
		name="t-burger-menu"
		@enter="onEnter"
		@after-leave="onAfterLeave"
	>
		<div
			v-if="isOpen"
			v-trap-focus.loop="{
				active: isOpen,
				additionalElementsBefore:
					'.c-navigation-header__persistent-buttons a, .c-navigation-header__persistent-buttons button',
			}"
			class="c-burger-menu"
		>
			<nav
				class="
					w-full
					px-layout-margin
					pt-md
					pb-xl
					flex flex-col
					justify-center
					items-center
				"
			>
				<header
					v-if="capButtons && capButtons.length"
					class="
						absolute
						inline-flex
						justify-start
						items-center
						left-layout-margin
						top-0
						h-menu
						>=1024:hidden
					"
				></header>

				<ul class="w-full">
					<li
						v-for="item in items"
						:key="item.id"
						:class="[
							'duration-200 ease-smooth-out',
							'text-white hover:text-primary-light',
							'text-left block',
							'border-b border-white border-opacity-10',
						]"
					>
						<NuxtLinkExt :to="item.url" class="text-burger">
							{{ item.title }}
						</NuxtLinkExt>
					</li>
				</ul>

				<footer
					v-if="capButtons && capButtons.length"
					class="
						mt-xl
						w-full
						flex
						justify-center
						items-center
						gap-x-10
					"
				>
					<BaseButton
						v-for="item in capButtons"
						:key="item.id"
						tag="NuxtLinkExt"
						:to="item.url"
						:target="item.target"
						type="primary-light"
					>
						{{ item.name }}
					</BaseButton>
				</footer>
			</nav>
		</div>
	</Transition>
</template>

<script>
import BaseButton from '../shared/Base/BaseButton.vue';

import trapFocus from '~/citi-baseline/directives/trap-focus.directive.js';

export default {
	name: 'BurgerMenu',
	directives: {
		trapFocus,
	},
	components: { BaseButton },
	props: {
		value: Boolean,
		items: {
			type: Array,
			default: () => [],
		},
		capButtons: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			isOpen: false,
		};
	},
	watch: {
		value: {
			immediate: true,
			handler(value) {
				this.isOpen = value;
			},
		},
		isOpen(value) {
			this.$emit('input', value);
		},
		$route() {
			this.isOpen = false;
		},
	},
	mounted() {
		document.addEventListener('keyup', this.checkEscape);
	},
	beforeDestroy() {
		document.removeEventListener('keyup', this.checkEscape);
	},
	methods: {
		checkEscape(e) {
			if (e.key === 'Escape') {
				this.isOpen = false;
			}
		},
		toggleMenu() {
			this.isOpen = !this.isOpen;
		},
		onEnter() {
			const __nuxt = document.body.querySelector('#__nuxt');
			document.documentElement.scrollTop = 0;
			document.body.scrollTop = 0;
			const scrollTopPosition =
				document.body.scrollTop || document.documentElement.scrollTop;
			const scrollLeftPosition =
				document.body.scrollLeft || document.documentElement.scrollLeft;
			__nuxt.style.overflow = 'hidden';
			__nuxt.scrollTop = scrollTopPosition;
			__nuxt.scrollLeft = scrollLeftPosition;
		},
		onAfterLeave() {
			const __nuxt = document.body.querySelector('#__nuxt');
			const scrollTopPosition = __nuxt.scrollTop;
			const scrollLeftPosition = __nuxt.scrollLeft;
			__nuxt.style.overflow = null;
			document.body.scrollTop = scrollTopPosition;
			document.documentElement.scrollTop = scrollTopPosition;
			document.body.scrollLeft = scrollLeftPosition;
			document.documentElement.scrollLeft = scrollLeftPosition;
		},
	},
};
</script>

<style lang="postcss">
.c-burger-menu {
	@apply flex flex-col justify-center items-center pt-menu bg-primary-button text-white max-h-visual-screen overflow-y-auto;
}

.t-burger-menu-enter-active,
.t-burger-menu-leave-active {
	@apply transition-transform duration-500;
}
.t-burger-menu-enter,
.t-burger-menu-leave-to {
	@apply transform -translate-y-full;
}
</style>
